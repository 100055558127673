.section-neogen {
  width: 100%;
  min-height: 85vh;
  background-color: #005b39;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-form {
  -webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.48);
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.48);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
}

.p-about-neogen {
  display: flex;
  flex-direction: column;
  font-size: 17px;
  font-weight: bold;
  color: #a1c842;
}

.div-btn-submit {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.div-all-option-product {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.div-option-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: 160px;
  height: 160px;
  justify-content: center;
  border-radius: 50%;
  margin: 10px 0;
}

.div-option-product-selected {
  -webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.48);
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.48);
  background-color: #a1c842;
  color: white;
}

.img-option-product {
  width: 100px;
}

.title-option-product {
  color: black;
}

.title-option-product-selected {
  background-color: #43a447;
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
  font-weight: bold;
  margin-bottom: -20px;
}

.title-option-product-selected:hover {
  color: white;
}

.div-priority {
  display: flex;
  margin: 5px 0;
}

.input-priority {
  width: 40px;
  height: 40px;
  text-align: center;
  margin-right: 5px;
}

.check-priority {
  margin-right: 5px;
}

.title-material {
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight: bold;
  font-size: 30px;
  margin: 20px 0;
  color: #005b39;
}

.title-material:hover {
  color: #005b39;
}

.div-all-material {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.div-material {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #a1c842;
  border-radius: 10px;
  margin: 5px;
  padding: 10px;
  width: 200px;
  cursor: pointer;
}

.img-material {
  width: 50px;
  height: 50px;
}

.name-material {
  text-align: center;
  font-size: 17px;
  color: black;
}

.name-material:hover {
  color: black;
}

.text-thanks {
  color: #a1c842;
  font-weight: bold;
  font-size: 20px;
}

.div-img-finish {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-trofeu-finish {
  width: 50%;
}

.text-finish {
  color: #a1c842;
  font-weight: bold;
  font-size: 30px;
  margin-top: 20px;
}

.finish-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.finish-card-option {
  color: white;
  margin: 10px;
  padding: 20px;
  width: 400px;
  text-align: center;
  font-size: 20px;
}

.finish-card-option:hover {
  cursor: default;
}

.title-product-selected {
  text-align: center;
  margin: 20px auto auto;
}

.header-neogen {
  background-color: white;
  width: 100%;
  height: 15vh;
  display: flex;
  align-items: center;
}

.header-neogen-img {
  width: 200px;
}

.div-imgs-process {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-process {
  width: 300px;
}

@media(max-width:800px){
  .div-imgs-process {
    flex-direction: column;
  }
}

@media (max-width: 900px) {
  .text-thanks {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .text-finish {
    text-align: center;
  }

  .header-neogen {
    justify-content: center;
  }
}
