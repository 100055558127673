.btn-submit {
  background-color: #4545e5;
  color: white;
  width: 140px;
  height: 45px;
  border-width: 0;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
}

.btn-submit:hover {
  background-color: #6565f2;
}

.btn-back {
  background-color: #d9166e;
  color: white;
  width: 140px;
  height: 45px;
  border-width: 0;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  margin-right: 10px;
}

.btn-back:hover {
  background-color: rgb(198, 4, 4);
}
