.section-kenvue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #ff6b6b; */
  background-color: white;
  min-height: 100vh;
}

.div-data-kenvue {
  width: 500px;
}

.card-question-kenvue {
  background-color: white;
  margin: 10px 0;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  /* border: 1px solid black; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;
}

.title-question-kenvue {
  display: flex;
  flex-direction: column;
}

.option-card-kenvue {
  text-align: center;
  color: black;
  margin: 5px 0;
  padding: 10px;
  transition: 0.2s all;
}

.option-card-kenvue:hover {
  color: black;
}

.option-card-kenvue-selected {
  color: white !important;
  background-color: #ff6b6b;
}

@media (max-width: 600px) {
  .div-data-kenvue {
    width: 95%;
  }
}
