.section-vibra {
  width: 100%;
  min-height: 100vh;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  background-color: #004206;
}

.card-vibra {
  width: 95%;
}

@keyframes is-rotating {
  to {
    transform: rotate(1turn);
  }
}

.a-loading {
  border: 6px solid #e5e5e5;
  border-top-color: #51d4db;
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 50%;
  animation: is-rotating 1s infinite;
}
