.section-files {
  width: 100%;
  min-height: 100vh;
  background-color:  #002374;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-files {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 20px;
}

.card-files {
  background-color: white;
  border-radius: 15px;
  padding: 5px;
  margin: 10px;
  box-shadow: 0 0 10px rgb(59, 59, 59);
  width: 250px;
}

.items-file{
  background-color: #002374;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  color: white;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: space-between;
}

.type-file {
  font-size: 70px;
}

.btn-download {
  text-decoration: none;
  color: white;
  background-color: #0060f5;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}

.btn-download:hover {
  background-color: white;
  color: green;
}
.btn-download2 {
  text-decoration: none;
  color: #9d24a1;
  background-color: white;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}
