.div-boehringer {
  width: 100%;
  min-height: 100vh;
  background-color: #08322a;
}

.card-boehringer {
  width: 90%;
  margin: auto;
}

.option-boehringer {
  width: 100%;
  /* background-color: #16e47c; */
  text-align: center;
  margin-bottom: 10px;
  padding: 10px;
  color: black;
  border-radius: 8px;
  transition: all 0.3s;
  border: 1px solid #08322a;
}

.option-boehringer-selected {
  background-color: #16e47c !important;
  color: white !important;
  text-decoration: none;
}

.btn-submit-boehringer {
  text-align: center;
  background-color: rgb(31, 153, 31);
  color: white;
  padding: 15px;
  font-size: 15px;
  margin-top: 20px;
}

.btn-submit-boehringer:hover {
  color: white;
}

.title-section-boefanini {
  text-align: center;
  margin: 30px auto 0;
  border-top: 2px solid rgb(237, 237, 237);
  padding-top: 10px;
  width: 90%;
}
