.section-profuse {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-profuse-header {
  width: 100%;
  padding: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
}

.img-profuse-logo-header {
  width: 170px;
}

.div-profuse-body {
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 700px;
}

.div-profuse-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;
}

.div-profuse-btn-actions {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.a-profuse-btn-action {
  border-radius: 10px;
  font-size: 20px;
  width: 140px;
  padding: 10px;
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
}

.a-profuse-btn-action:hover {
  color: white;
}

.div-profuse-product {
  display: flex;
  align-items: center;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px -5px; */
  background-color: whitesmoke;
  margin: 5px 0;
  border-radius: 10px;
}

.img-profuse-product {
  width: 100px;
  margin-right: 15px;
}

.a-profuse-product {
  color: black;
  width: 100%;
  text-align: center;
}

.a-profuse-product:hover {
  color: black;
}

@media (max-width: 700px) {
  .div-profuse-body {
    width: 95%;
  }
}
