.section-card-petropolis {
  background-color: #ca1c27;
  width: 100%;
  min-height: 100vh;
  margin: auto;
  padding: 20px 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.option-pdv {
  color: black;
  background-color: white;
  margin: 10px auto;
  padding: 10px;
  font-size: 20px;
  border-radius: 15px;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option-pdv:hover {
  color: black;
}

.option-pdv-disabled {
  background-color: #b0afb5;
}

.option-pdv-disabled:hover {
  cursor: default;
}

.card-question {
  width: 100%;
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  margin: 10px 0;
}

.div-avaliation-petropolis {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.option-avaliation {
  border: 1px solid black;
  color: black;
  transition: 0;
}

.option-avaliation:hover {
  color: black;
}

.option-avaliation-selected {
  background-color: rgb(96 186 96);
  border-color: rgb(96 186 96);
  color: white;
  transition: 0.1s;
}

.option-avaliation-selected:hover {
  color: white;
}

.icon-check {
  background-color: green;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 20px;
  color: white;
}

.div-loading {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
