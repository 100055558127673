.div-avaliation {
  display: flex;
  justify-content: space-between;
}

.option-avaliation {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  width: 20%;
  border-radius: 8px;
  transition: 0.4s;
  cursor: pointer;
}
