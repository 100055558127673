.section-boe {
  width: 100%;
  min-height: 100vh;
  background-color: #08322a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-boe {
  width: 90%;
  margin-top: 30px;
}

.btn-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(46, 150, 46);
  width: 100%;
  margin-top: 40px;
  transition: all 0.3s;
}

.btn-submit:hover {
  color: white;
  background-color: rgb(40, 121, 40);
}

.btn-submit-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(149, 149, 149);
  width: 100%;
  margin-top: 40px;
  transition: all 0.3s;
  height: 45px;
  border-width: 0;
  border-radius: 6px;
  color: white;
}

.btn-submit-disabled:hover {
  color: white;
  cursor: default;
}

.div-options-question-boe {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.option-question-boe {
  background-color: rgb(226, 226, 226);
  color: black;
  padding: 15px;
  margin: 10px;
  text-align: center;
  transition: all 0.4s;
}

.option-question-boe:hover {
  background-color: #08322a;
  color: #16e47c;
}

.selected-option-question-boe {
  background-color: #08322a;
  color: #16e47c;
  cursor: pointer;
  border: 2px solid black !important;
}

.check-true {
  background-color: rgb(95, 173, 95) !important;
  color: white !important;
}

.check-false {
  background-color: rgb(255, 60, 60) !important;
  color: white !important;
}

.btn-close-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(46, 150, 46);
  color: white;
  width: 100%;
  margin-top: 40px;
  transition: all 0.3s;
  height: 45px;
  border-radius: 8px;
}
